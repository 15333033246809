import { useContext, useCallback } from 'react';

import { AuthContext } from '../contexts/AuthContext';
import { pushGAEvent } from '../utilities/pushGAEvent';

export const useGTMEvent = ({
  event,
  eventCategory,
  eventAction,
  eventLabel,
}) => {
  const { user } = useContext(AuthContext);

  const pushGTMEvent = useCallback(() => {
    pushGAEvent({
      event,
      eventCategory,
      eventAction,
      eventLabel,
      user_id: user?.id,
    });
  }, [event, eventAction, eventCategory, eventLabel, user?.id]);

  return pushGTMEvent;
};
