// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-moje-js": () => import("./../../../src/pages/moje/[...].js" /* webpackChunkName: "component---src-pages-moje-js" */),
  "component---src-pages-podporovane-banky-js": () => import("./../../../src/pages/podporovane-banky.js" /* webpackChunkName: "component---src-pages-podporovane-banky-js" */),
  "component---src-pages-test-01-js": () => import("./../../../src/pages/test-01.js" /* webpackChunkName: "component---src-pages-test-01-js" */),
  "component---src-pages-ucet-nove-heslo-js": () => import("./../../../src/pages/ucet/nove-heslo.js" /* webpackChunkName: "component---src-pages-ucet-nove-heslo-js" */),
  "component---src-pages-ucet-prihlaseni-js": () => import("./../../../src/pages/ucet/prihlaseni.js" /* webpackChunkName: "component---src-pages-ucet-prihlaseni-js" */),
  "component---src-pages-ucet-registrace-js": () => import("./../../../src/pages/ucet/registrace.js" /* webpackChunkName: "component---src-pages-ucet-registrace-js" */),
  "component---src-pages-ucet-sms-kod-js": () => import("./../../../src/pages/ucet/sms-kod.js" /* webpackChunkName: "component---src-pages-ucet-sms-kod-js" */),
  "component---src-pages-ucet-zapomenute-heslo-js": () => import("./../../../src/pages/ucet/zapomenute-heslo.js" /* webpackChunkName: "component---src-pages-ucet-zapomenute-heslo-js" */),
  "component---src-pages-ups-401-js": () => import("./../../../src/pages/ups/401.js" /* webpackChunkName: "component---src-pages-ups-401-js" */),
  "component---src-pages-ups-403-js": () => import("./../../../src/pages/ups/403.js" /* webpackChunkName: "component---src-pages-ups-403-js" */),
  "component---src-pages-ups-404-js": () => import("./../../../src/pages/ups/404.js" /* webpackChunkName: "component---src-pages-ups-404-js" */),
  "component---src-pages-ups-500-js": () => import("./../../../src/pages/ups/500.js" /* webpackChunkName: "component---src-pages-ups-500-js" */),
  "component---src-pages-ups-503-js": () => import("./../../../src/pages/ups/503.js" /* webpackChunkName: "component---src-pages-ups-503-js" */)
}

